const windowsCondition = [{ attribute: null, value: null, condition_operation: 'and', condition: { value: 'In', label: 'IS' } }]
const linuxCondition = { type: 1, custom: { condition: { value: "In", label: "IS" }, values: [] } }
const initialState = {
    profile_id: null,
    conditions: windowsCondition,
    devicesNumber: 1,
    configured: false,
    minutes: 2,
    patchCount: 4,
    policyName: '',
    notes: '',
    repeat_schedule: { value: 'none', label: 'None' },
    repeat_time: '',
    policy_type: '',
    selectedGroups: [],
    groups: [],
    week: {
        friday: 0,
        monday: 0,
        saturday: 0,
        sunday: 0,
        thursday: 0,
        tuesday: 0,
        wednesday: 0
    }
    // finish: false,
}
const patchPolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PATCH_WINDOWS_POLICY_APP': {
            return {
                ...state,
                ...action.value // Merging action.value into the state
            }
        }
        case 'PATCH_WINDOWS_POLICY_DATA': {
            return { ...state, [action.value.key]: action.value.data }
        }
        case 'PATCH_WINDOWS_FINISH_POLICY': {
            return { ...state, finish: action.value }
        }
        case 'PATCH_WINDOWS_INITIAL_POLICY': {
            const { policy_type } = action.value
            return {
                ...initialState,
                policy_type,
                conditions: policy_type === 'windows' ? windowsCondition : linuxCondition
            }
        }
        default:
            return state
    }
}
export default patchPolicyReducer