// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import reports from './reports'
import assetPage from './asset-page'
import assets from './assets'
import devices from './devices'
import groups from './groups'
import version from './version'
import rateCompliance from './rate-compliance'
import cyscan from './cyscan'
import identity from './identity'
import dnsSecurity from './dns-security'
import policyWizared from './policy-wizared'
import patchManagerWindows from './advanced-filters/patch-manegment/maneger/windows'
import patchManagerLinux from './advanced-filters/patch-manegment/maneger/linux'
import patchHistoryWindows from './advanced-filters/patch-manegment/history/windows'
import patchHistoryLinux from './advanced-filters/patch-manegment/history/linux'
import PatchtIntelligence from './advanced-filters/patch-manegment/patch-intelligence'


import patchPolicy from './patch-policy'
import chat from '@views/ticketing-center/chat/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  reports,
  assetPage,
  assets,
  devices,
  groups,
  version,
  rateCompliance,
  cyscan,
  identity,
  dnsSecurity,
  policyWizared,
  patchManagerWindows,
  patchManagerLinux,
  patchHistoryWindows,
  patchHistoryLinux,
  PatchtIntelligence,
  patchPolicy,
  chat
})

export default rootReducer
