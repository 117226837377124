const primaryFilters = {
    windows_type: {
        label: 'Windows Type',
        default: true,
        show: true,
        options: [
            { value: [0, 1], label: 'Workstations' },
            { value: [2, 3, 4, 5], label: 'Servers' }
        ]
    },
    installation_status: {
        label: 'Installation Status',
        default: true,
        show: true,
        options: [
            { value: 'N/A', label: 'N/A' },
            { value: 'Succeeded', label: 'Succeeded' },
            { value: 'Pending', label: 'Pending' },
            { value: 'In Progress', label: 'In Progress' },
            { value: 'Failed', label: 'Failed' },
            { value: 'DOWNLOAD FAILED', label: 'DOWNLOAD FAILED' },
            { value: 'ALREADY INSTALLED', label: 'ALREADY INSTALLED' }
        ]
    },
    asset_status: {
        label: 'Asset Status',
        default: true,
        show: true,
        options: [
            { value: 'Online', label: 'Online' },
            { value: 'Offline', label: 'Offline' }
        ]
    },
    groups: {
        label: 'Groups',
        default: false,
        show: false,
        optionsName: 'groups'
    },
    serial_number: {
        label: 'Serial Number',
        default: false,
        show: false,
        optionsName: 'serial_number'
    },
    operating_system: {
        label: 'Operating System',
        default: true,
        show: true,
        optionsName: 'operating_system'
    },
    update_category: {
        label: 'Update Category',
        default: false,
        show: false,
        optionsName: 'update_category'
    }
}
const initialState = {
    filters: {},
    filtersType: primaryFilters,
    change: false
}
const PatchHistoryWindowsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INITIAL_PATCH_HISTORY_WINDOWS_FILTERS': {
            return initialState
        }
        case 'HANDLE_PATCH_HISTORY_WINDOWS_FILTERS': {
            let filters = state.filters

            if (action.value && action.value.name) {
                const values = action.value.values

                if (values && values.length > 0) {
                    filters = {
                        ...filters,
                        [action.value.name]: action.value
                    }
                } else {
                    delete filters[action.value.name]
                }

                // state.filtersType = {
                //     ...state.filtersType,
                //     [action.value.name]: {
                //         ...state.filtersType[action.value.name],
                //         show: true
                //     }
                // }
            }

            return { ...state, filters, change: true }
        }
        case 'HANDLE_APPEND_PATCH_HISTORY_WINDOWS_FILTERS': {
            let filters = state.filters

            if (action.value && action.value.name) {
                let values = action.value.values

                if (values && values.length > 0) {
                    values = [
                        ...(filters[action.value.name]?.values || []),
                        ...values
                    ]

                    values = Array.from(new Set(values))

                    filters = {
                        ...filters,
                        [action.value.name]: {
                            name: action.value.name,
                            operation: action.value.operation,
                            values
                        }
                    }
                } else {
                    delete filters[action.value.name]
                }

                state.filtersType = {
                    ...state.filtersType,
                    [action.value.name]: {
                        ...state.filtersType[action.value.name],
                        show: true
                    }
                }
            }

            return { ...state, filters, change: true }
        }
        case 'HANDLE_ADD_PATCH_HISTORY_WINDOWS_FILTER': {
            if (action.value) {
                return {
                    ...state,
                    filtersType: {
                        ...state.filtersType,
                        [action.value]: {
                            ...state.filtersType[action.value],
                            show: true
                        }
                    }
                }
            }
            return state
        }
        case 'HANDLE_REMOVE_PATCH_HISTORY_WINDOWS_FILTER': {
            if (action.value) {
                const updatedFilters = { ...state.filters }
                const updatedFiltersType = { ...state.filtersType }

                if (updatedFilters[action.value]?.values?.length) {
                    state.change = true
                }

                delete updatedFilters[action.value]

                updatedFiltersType[action.value] = {
                    ...updatedFiltersType[action.value],
                    show: false
                }

                return {
                    ...state,
                    filters: updatedFilters,
                    filtersType: updatedFiltersType,
                    change: state.change
                }
            }
            return state
        }
        case 'HANDLE_RESET_PATCH_HISTORY_WINDOWS_FILTERS': {
            return {
                filters: {},
                filtersType: primaryFilters,
                change: true
            }
        }
        case 'HANDLE_CHANGE_PATCH_HISTORY_WINDOWS': {
            return {
                ...state,
                change: action.value
            }
        }
        case 'HANDLE_CHANGE_TICKET_PATCH_HISTORY_WINDOWS_FILTER': {
            return {
                ...state,
                ticketFilter: action.value,
                change: true
            }
        }
        default:
            return state

    }
}
export default PatchHistoryWindowsReducer