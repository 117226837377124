const primaryFilters = {
    exploited: {
        label: 'Exploited',
        default: true,
        show: true,
        options: [
            { value: 'Actively Exploited', label: 'Actively Exploited' },
            { value: 'Unproven', label: 'Unproven' }
        ]
    },
    status: {
        label: 'Status',
        default: true,
        show: true,
        options: [
            { value: 'Solved', label: 'Solved' },
            { value: 'Not Solved', label: 'Not Solved' }
        ]
    },
    kbs: {
        label: 'KBS',
        default: false,
        show: false,
        optionsName: 'kbs'
    },
    cve: {
        label: 'CVE',
        default: false,
        show: false,
        optionsName: 'cve'
    },
    restart_required: {
        label: 'Restart Required',
        default: false,
        show: false,
        optionsName: 'restart_required'
    },
    sub_type: {
        label: 'Sub Type',
        default: false,
        show: false,
        optionsName: 'sub_type'
    }
}
const initialState = {
    filters: {},
    filtersType: primaryFilters,
    change: false
}
const PatchManagementIntelligenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INITIAL_PATCH_INTELLIGENCE_FILTERS': {
            return initialState
        }
        case 'HANDLE_PATCH_INTELLIGENCE_FILTERS': {
            let filters = state.filters

            if (action.value && action.value.name) {
                const values = action.value.values

                if (values && values.length > 0) {
                    filters = {
                        ...filters,
                        [action.value.name]: action.value
                    }
                } else {
                    delete filters[action.value.name]
                }

                // state.filtersType = {
                //     ...state.filtersType,
                //     [action.value.name]: {
                //         ...state.filtersType[action.value.name],
                //         show: true
                //     }
                // }
            }

            return { ...state, filters, change: true }
        }
        case 'HANDLE_APPEND_PATCH_INTELLIGENCE_FILTERS': {
            let filters = state.filters

            if (action.value && action.value.name) {
                let values = action.value.values

                if (values && values.length > 0) {
                    values = [
                        ...(filters[action.value.name]?.values || []),
                        ...values
                    ]

                    values = Array.from(new Set(values))

                    filters = {
                        ...filters,
                        [action.value.name]: {
                            name: action.value.name,
                            operation: action.value.operation,
                            values
                        }
                    }
                } else {
                    delete filters[action.value.name]
                }

                state.filtersType = {
                    ...state.filtersType,
                    [action.value.name]: {
                        ...state.filtersType[action.value.name],
                        show: true
                    }
                }
            }

            return { ...state, filters, change: true }
        }
        case 'HANDLE_ADD_PATCH_INTELLIGENCE_FILTER': {
            if (action.value) {
                return {
                    ...state,
                    filtersType: {
                        ...state.filtersType,
                        [action.value]: {
                            ...state.filtersType[action.value],
                            show: true
                        }
                    }
                }
            }
            return state
        }
        case 'HANDLE_REMOVE_PATCH_INTELLIGENCE_FILTER': {
            if (action.value) {
                const updatedFilters = { ...state.filters }
                const updatedFiltersType = { ...state.filtersType }

                if (updatedFilters[action.value]?.values?.length) {
                    state.change = true
                }

                delete updatedFilters[action.value]

                updatedFiltersType[action.value] = {
                    ...updatedFiltersType[action.value],
                    show: false
                }

                return {
                    ...state,
                    filters: updatedFilters,
                    filtersType: updatedFiltersType,
                    change: state.change
                }
            }
            return state
        }
        case 'HANDLE_RESET_PATCH_INTELLIGENCE_FILTERS': {
            return {
                filters: {},
                filtersType: primaryFilters,
                change: true
            }
        }
        case 'HANDLE_CHANGE_PATCH_INTELLIGENCE': {
            return {
                ...state,
                change: action.value
            }
        }
        case 'HANDLE_CHANGE_TICKET_PATCH_INTELLIGENCE_FILTER': {
            return {
                ...state,
                ticketFilter: action.value,
                change: true
            }
        }
        default:
            return state

    }
}
export default PatchManagementIntelligenceReducer